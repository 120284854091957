import axios from "axios";
import config from '../config';

let client = axios.create({
    baseURL: config.api_endpoint,
    responseType: "json"
});

// Search

export function searchEmojis (query) {
    return client.get('/search?q=' + query, {mode: 'cors'});
}

// Popular

export function popularEmojis () {
    return client.get('/popular?limit=24', {mode: 'cors'});
}
