import React, {Component} from 'react';
import {searchEmojis, popularEmojis} from '../api/emojisworld'
import Emoji from '../components/Emoji'

class SearchBar extends Component {

    constructor(props) {
        super(props);

        this.timeout = 0;

        this.state = {
            text : '',
            isLoading : false,
            emojis: [],
            error: false
        };
    }

    componentDidMount() {
        popularEmojis().then(response => {
            this.setState({emojis: response.data.results, isLoading: false, error: false });
        }).catch(error => {
            console.error(error);
            this.setState({error: true, isLoading: false });
        });
    }

    handleChange = event => {

        const query = event.target.value;

        if(this.timeout) clearTimeout(this.timeout);

        if (query.length > 0) {

            this.timeout = setTimeout(() => {
                this.setState({isLoading: true});

                searchEmojis(query).then(response => {
                    this.setState({text: query, emojis: response.data.results, isLoading: false, error: false });
                }).catch(error => {
                    console.error(error);
                    this.setState({text: query, error: true, isLoading: false });
                });
            }, 400);
        }
        else {
            popularEmojis().then(response => {
                this.setState({text: '', emojis: response.data.results, isLoading: false, error: false });
            }).catch(error => {
                console.error(error);
                this.setState({text: '', error: true, isLoading: false });
            });
        }
    };

    _displayLoader() {
        if (this.state.isLoading){
            return (
                <div id={'loader-input'} className="spinner-border text-light" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            )
        }
    }

    _displayEmojis () {

        if (this.state.error) {

            return (
                <div className="text-center no-emojis-find">
                    <p className={'result-emoji'} dangerouslySetInnerHTML={{__html: '&#x1F623'}}/>
                    <p className={'no-emojis-find-text'}>Oops! An error occured. <br/> Please try again later</p>
                </div>
            );
        }

        else {

            if (this.state.emojis.length) {
                return (
                    <div>
                        { (this.state.text.length === 0) ? <p className={'header-text'}>Populars emojis</p> : null}
                        <div id="emojis-result" className="row mb-5">
                            {this.state.emojis.map((emoji, i) => (
                                <Emoji key={i} emoji={emoji} />
                            ))}
                        </div>
                    </div>
                )
            }

            else {
                return (
                    <div className="text-center no-emojis-find">
                        <p className={'result-emoji'} dangerouslySetInnerHTML={{ __html : '&#x1F622' }}/>
                        <p className={'no-emojis-find-text'}>No emojis found</p>
                    </div>
                );
            }
        }
    }

    render() {

        return (
            <section id="demo">
                    <div className={'group-input'}>
                        <input onChange={this.handleChange} type="text" placeholder="Search emojis (in english only) ... " id="emojis-search-input"/>
                        {this._displayLoader()}
                    </div>
                    {this._displayEmojis()}
            </section>
        );
    }
}

export default SearchBar;