import React, {Component} from 'react';
import { Result, Button } from 'antd';

class NotFound extends Component {
    render() {
        return (
            <div className={'center'}>
                <Result
                    status="404"
                    title={<span style={{color:'white'}}>404</span>}
                    subTitle={<span style={{color:'white'}}>La page à la quelle vous essayez d'accéder n'existe pas</span>}
                    extra={<Button href={'/'} type="primary">Revenir à l'Application</Button>}
                />
            </div>
        );
    }
}

export default NotFound;