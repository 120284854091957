import React, {Component, Fragment} from 'react';
import {message} from 'antd';

class Emoji extends Component {

    copy (emoji) {
        navigator.clipboard.writeText(emoji).then(function() {
            message.success('Emoji : ' + emoji + String.fromCharCode(160) + ' copié !!' , 4)
        }, function() {
            message.error('Erreur lors de la copie' , 4)
        });
    }

    _displayEmoji() {

        const {emoji} = this.props;
        return (
            <Fragment>
                <div style={{cursor:'pointer'}} onClick={() => this.copy(emoji.emoji)}>
                    <p style={{fontSize: '4.0em',marginBottom:0, fontFamily:'none'}} dangerouslySetInnerHTML={{ __html : '&#x' + emoji.unicode.split(' ').join(';&#x') }} />
                    <p><strong>{emoji.name}</strong></p>
                </div>
            </Fragment>
        )
    }


    render() {
        return (
            <div className="col-sm-3 col-6 emojis-item">
                {this._displayEmoji()}
            </div>
        )
    }
}

export default Emoji;