import React, {Fragment} from 'react';

import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'antd/dist/antd.min.css';

import SearchBar from './components/SearchBar';

export default function App () {
    return (
        <Fragment>
            <header>
                <span className={'header_title'}>Emojis World</span>
                <nav className={'menu'}>
                    <a href="https://github.com/abourtnik/emojis-world">Github</a>
                </nav>
            </header>
            <section id="index">
                <div id="index-content">
                    <h1 id={'title'}>Open Source REST API For Emojis</h1>
                    <h2 id={'second_title'}>3652 Emojis Availaible</h2>
                    <button onClick={() => document.getElementById('search').scrollIntoView()} id="begin-button">
                        <span></span>
                    </button>
                </div>
            </section>
            <section id={'search'} style={{marginTop:20}}>
                <div className="container-fluid">
                    <SearchBar></SearchBar>
                </div>
            </section>
        </Fragment>
    );
}